import React from "react";

import { Icon } from "../.";

export const Copy = (props) => {
  return (
    <Icon
      glyph={() => (
        <svg width={24} height={24} viewBox="0 0 24 24">
          <path
            d="M16.5 21v1.875c0 .621-.504 1.125-1.125 1.125H2.625A1.125 1.125 0 011.5 22.875V5.625c0-.621.504-1.125 1.125-1.125H6v13.875A2.628 2.628 0 008.625 21H16.5zm0-16.125V0H8.625C8.004 0 7.5.504 7.5 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V6h-4.875A1.128 1.128 0 0116.5 4.875zm5.67-1.454L19.08.329A1.125 1.125 0 0018.283 0H18v4.5h4.5v-.284c0-.298-.119-.585-.33-.795z"
            fill="currentColor"
            fillRule="nonzero"
          />
        </svg>
      )}
      {...props}
    />
  );
};
